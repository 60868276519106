$(document).ready(function () {
  var formButtons = $('#sign-in-button, #restore-password-button');
  var login = $('#user_login');
  var password = $('#user_password');
  var phone = $('#user_phone');
  var phone_mask = new Inputmask('+7 (999) 999-99-99');
  phone_mask.mask(phone);

  $('#user_login').on("input", function() {
    this.value = this.value.toLowerCase();
    this.value = this.value.replaceAll(/[^a-z0-9]/gi, "");
  });

  $('#user_login, #user_password, #user_phone').on('keyup', function() {
    if (valid(login)) {
      if (validPhone(phone)) {
        $('#restore-password-button').removeAttr('disabled');
      } else {
        $('#restore-password-button').attr('disabled','disabled');
      }
    }
    else {
      $('#restore-password-button').attr('disabled','disabled');
    }
  });

  $("#home-tab").on('click', function() {
    $('#user_password').prop("disabled", false);
    $('#user_phone').prop("disabled", true);
  });

  $("#restore-tab").on('click', function() {
    $('#user_phone').prop("disabled", false);
    $('#user_password').prop("disabled", true);
  });

  $('#restore-password-button').click(function() {
    var value = Inputmask.unmask(phone.val(), { mask: "+7 (999) 999-99-99" });
    $.ajax({
      url: '/users/sessions/restore_password',
      method: 'POST',
      data: {login: login.val(), phone: value},
      dataType: "script"
    });
  });

  var description = false;
  $('.fa-question').click(function() {
    if (description) {
      $('#description').hide();
      description = false;
    } else {
      $('#description').show();
      description = true;
    }
  });
  $('.fa-eye').click(function() {
    if ($(this).hasClass('fa-eye-slash')) {
      $(this).removeClass('fa-eye-slash');
      $(this).addClass('fa-eye');
      $(this).siblings('input').prop('type', 'password');
    } else {
      $(this).removeClass('fa-eye');
      $(this).addClass('fa-eye-slash');
      $(this).siblings('input').prop('type', 'text');
    }
  });
  login.focus();
});

function valid(input) {
  const regex = new RegExp('[а-яА-Я]');
  if (regex.test(input.val())) {
    input.css('border-color', 'red');
    return false;
  }
  else {
    input.css('border-color', '');
    return true;
  }
}

function validPhone(input) {
  var value = Inputmask.unmask(input.val(), { mask: "+7 (999) 999-99-99" });
  const regex = new RegExp('^[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}$');
  if (regex.test(value)) {
    input.css('border-color', '');
    return true;
  }
  else {
    input.css('border-color', 'red');
    return false;
  }
}